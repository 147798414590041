import { Product } from "@akinon/next/types";

export const identifyVisitor = (email: string) => {
  if (typeof window !== 'undefined' && window.ScarabQueue) {
    window.ScarabQueue.push(['setEmail', email]);
  } else {
    console.error("ScarabQueue is not initialized or window is unavailable");
  }
};

export const setCustomerID = (id:string) => {
  if (typeof window !== 'undefined' && window.ScarabQueue) {
    window.ScarabQueue.push(['setCustomerId', id]);
  } else {
    console.error("ScarabQueue is not initialized or window is unavailable");
  }
};

export const trackCustomer = (session: any) => {
  identifyVisitor(session?.user?.email);
  setCustomerID(session?.user?.pk?.toString());
}

export const trackLogin = (session: any) => {
  const login = {
    'login' : {
      "customerId": session?.user?.pk.toString()
    }
  }
  setCustomerID(session?.user?.pk.toString());
  trackEvent('tag', login);
  triggerScarabQueue();
};

export const trackLogout = (session:any, basket:any) => {
  const logout = {
    'sign-out' : {
      "customerId": session?.user?.pk.toString()
    }
  }
  setCustomerID(session?.user?.pk.toString());
  trackEventWithCart('tag', logout, basket);
};

export const trackCart = (cartItems) => {
  if (typeof window !== 'undefined' && window.ScarabQueue) {
    window.ScarabQueue.push(['cart', cartItems]);
  } else {
    console.error("ScarabQueue is not initialized or window is unavailable");
  }
};

export const trackEventWithCart = (eventName: string, options: any, cartItems: any[]) => {
  const items = cartItems.map((item) => ({
    item: item.product.pk,
    price: Number(item.price),
    quantity: item.quantity
  }));

  if (typeof window !== 'undefined' && window.ScarabQueue) {
    trackEvent(eventName, options);

    trackCart(items);

    triggerScarabQueue();
  } else {
    console.error("ScarabQueue is not initialized or window is unavailable");
  }
};

export const trackPurchase = (order) => {
  const groupedItems = {};

  order?.orderitem_set.forEach((item) => {
    const pk = item.product.pk;

    if (!groupedItems[pk]) {
      groupedItems[pk] = {
        item: pk,
        price: Number(item.price),
        quantity: item.quantity || 1
      };
    } else {
      groupedItems[pk].quantity += item.quantity || 1;
    }
  });

  const purchaseDetails = {
    orderId: order.id,
    items: Object.values(groupedItems),
  };

  trackCart([]);
  trackEvent('purchase', purchaseDetails);
  triggerScarabQueue();
};

export const trackBannerClick = (session:any, banner:any, basket:any) => {
  if(session){
    trackCustomer(session);
  }
  trackEventWithCart('tag', banner, basket);
}

export const triggerScarabQueue = () => {
  if (typeof window !== 'undefined' && window.ScarabQueue) {
    window.ScarabQueue.push(['go']);
  } else {
    console.error("ScarabQueue is not initialized or go command already triggered.");
  }
};

export const trackEvent = (eventName: string, options: Record<string, any>) => {
  if (typeof window !== 'undefined') {
    const checkScarabQueue = () => {
      if (window.ScarabQueue) {
        window.ScarabQueue.push([eventName, options]);
      } else {
        console.error("Emarsys (ScarabQueue) is not initialized, retrying...");
        setTimeout(checkScarabQueue, 100);
      }
    };

    checkScarabQueue();
  } else {
    console.error("Window is not available");
  }
};

export const trackAddToCart = (product: Product, session:any) => {
  if(session){
    setCustomerID(session.user.pk.toString());
  }
  trackEvent('cart', [
    {
      item: product.pk,
      price: Number(product.price),
      quantity: product.quantity || 1
    }
  ]);
  triggerScarabQueue();
};
