'use client';

import { useState, useEffect } from 'react';
import { signOut, useSession } from 'next-auth/react';
import { useLogoutMutation } from '@akinon/next/data/client/user';
import { ROUTES } from '@theme/routes';

import { Icon, Link } from '@theme/components';
import { usePathname } from 'next/navigation';
import clsx from 'clsx';
import { useLocalization } from '@akinon/next/hooks';
import { trackLogout } from '@theme/utils/emarsys';
import { useGetBasketQuery } from '@akinon/next/data/client/basket';
import { useWindowSize } from '@theme/hooks/use-window-size';
import { swanLogout } from '@theme/utils/swan';
import { removeCookie } from '@akinon/next/utils';

const ACCOUNT_MENU_ITEMS = [
  {
    translationKey: 'account.base.menu.my_account',
    href: ROUTES.ACCOUNT,
    icon: 'my-account',
    iconActive: 'my-account',
    testId: 'account'
  },
  {
    translationKey: 'account.base.menu.my_orders',
    href: ROUTES.ACCOUNT_ORDERS,
    icon: 'orders-receipts-fill',
    iconActive: 'orders-receipts-fill',
    testId: 'account-orders',
    hasSub: true
  },
  {
    translationKey: 'account.base.menu.lulu_wallet',
    href: ROUTES.ACCOUNT_WALLET,
    icon: 'wallet',
    iconActive: 'wallet',
    testId: 'account-lulu-wallet',
    hasSub: true
  },
  {
    translationKey: 'account.base.menu.my_profile',
    href: ROUTES.ACCOUNT_PROFILE,
    icon: 'my-profile',
    iconActive: 'my-profile',
    testId: 'account-profile'
  },
  {
    translationKey: 'account.base.menu.addresses',
    href: ROUTES.ACCOUNT_ADDRESS,
    icon: 'location-pin-icon',
    iconActive: 'location-pin-icon',
    testId: 'account-addresses'
  },
  {
    translationKey: 'account.base.menu.my_cards',
    href: ROUTES.ACCOUNT_CARDS,
    icon: 'my-cards',
    iconActive: 'my-cards',
    testId: 'account-cards'
  },
  {
    translationKey: 'account.base.menu.favorites',
    href: ROUTES.ACCOUNT_WISHLIST,
    icon: 'favorite-default',
    iconActive: 'favorite-filled',
    testId: 'account-wishlist'
  },
  {
    translationKey: 'account.base.menu.vouchers',
    href: ROUTES.ACCOUNT_COUPONS,
    icon: 'bottom-nav-offers-stroke',
    iconActive: 'bottom-nav-offers-filled',
    testId: 'account-coupons'
  },
  {
    translationKey: 'account.base.menu.faq',
    href: ROUTES.ACCOUNT_FAQ,
    icon: 'faqs',
    iconActive: 'faqs',
    testId: 'account-faq'
  },
  {
    translationKey: 'account.base.menu.contact',
    href: ROUTES.ACCOUNT_CONTACT,
    icon: 'contact',
    iconActive: 'contact',
    testId: 'account-contact'
  }
];

export default function AccountMenu() {
  const { t } = useLocalization();
  const { data: session } = useSession();
  const { data: basket } = useGetBasketQuery();
  const [logout] = useLogoutMutation();
  const pathname = usePathname();
  const { width } = useWindowSize();
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(width < 768);
  }, [width]);

  useEffect(() => {
    const cleanedPathname = pathname.replace(/^\/[a-z]{2}-[a-z]{2}\//, '/');

    const currentItem =
      ACCOUNT_MENU_ITEMS.find((item) => {
        if (item.hasSub) return cleanedPathname.includes(item.href);
        return cleanedPathname === item.href;
      }) || ACCOUNT_MENU_ITEMS[0];
    setSelectedItem(currentItem);
  }, [pathname]);

  const handleLogout = async () => {
    trackLogout(session, basket.basketitem_set);
    swanLogout();
    logout()
      .unwrap()
      .then(() => {
        removeCookie('selectedAddress');
        removeCookie('userLocation');
        signOut({
          callbackUrl: '/'
        });
      })
      .catch();
  };

  const handleMenuItemClick = (item) => {
    setSelectedItem(item);
    if (window.innerWidth < 1024) {
      setIsMenuExpanded(false);
    }
  };

  const toggleMenuExpansion = () => {
    setIsMenuExpanded(!isMenuExpanded);
  };

  const isMenuActive = (pathname, menuItem) => {
    const cleanedPathname = pathname.replace(/^\/[a-z]{2}-[a-z]{2}\//, '/');
    if (menuItem.hasSub) return cleanedPathname.includes(menuItem.href);
    return cleanedPathname === menuItem.href;
  };

  return (
    <div className="relative">
      <div
        className={clsx(
          'mb-5 flex h-max w-full flex-col gap-[26px] rounded-2xl border border-gray-380 border-opacity-0 bg-white py-[14px] md:w-[240px] lg:me-11',
          { 'lg:block': !isMobile || isMenuExpanded }
        )}
        style={{
          boxShadow: '10px 4px 125px 0px #0000000D',
          transition: 'max-height 0.3s ease-in-out'
        }}
      >
        {isMobile && (
          <button
            onClick={toggleMenuExpansion}
            className="flex w-full items-center justify-between border-b px-4 py-2"
          >
            <span>{selectedItem && t(selectedItem.translationKey)}</span>
            <Icon
              name={isMenuExpanded ? 'chevron-up' : 'chevron-down'}
              size={24}
            />
          </button>
        )}

        {(isMenuExpanded || !isMobile) && (
          <div className="flex h-auto w-full flex-col gap-[10px] px-[14px]">
            {ACCOUNT_MENU_ITEMS.map((item) => (
              <Link
                key={item.testId}
                href={item.href}
                data-testid={item.testId}
                className={clsx(
                  'text-sm font-semibold text-black',
                  isMenuActive(pathname, item) && 'text-primary !opacity-100'
                )}
              >
                <div
                  className={clsx(
                    'flex h-10 w-full flex-row items-center gap-[14px] rounded-[10px] px-[10px] py-2',
                    isMenuActive(pathname, item) &&
                      'border-l-[3px] border-solid border-primary bg-primary bg-opacity-10'
                  )}
                  onClick={() => handleMenuItemClick(item)}
                >
                  <Icon
                    name={
                      isMenuActive(pathname, item) ? item.iconActive : item.icon
                    }
                    size={20}
                    className={clsx(
                      'flex w-6 justify-center text-black opacity-50',
                      isMenuActive(pathname, item) &&
                        'text-primary !opacity-100'
                    )}
                  />
                  <span
                    className={clsx(
                      'text-sm font-semibold text-black opacity-50',
                      isMenuActive(pathname, item) &&
                        'text-primary !opacity-100'
                    )}
                  >
                    {selectedItem && t(item.translationKey)}
                  </span>
                </div>
              </Link>
            ))}

            <div className="flex h-[66px] w-full border-t border-gray-380 px-[10px] pt-[26px]">
              <div className="flex h-10 w-full flex-row items-center gap-[14px] rounded-[10px] py-2">
                <button
                  className="w-full"
                  data-testid="account-logout"
                  onClick={handleLogout}
                >
                  <span className="flex flex-row items-center gap-[14px]">
                    <Icon
                      name="logout"
                      size={20}
                      className="text-black opacity-50"
                    />
                    <span className="text-sm font-semibold text-black opacity-50">
                      {t('account.base.menu.logout')}
                    </span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
