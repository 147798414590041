'use client';

import { useLocalization } from '@akinon/next/hooks';
import { FormModal } from '@theme/components/form-modal';
import React, { useState } from 'react';
import { AddressItem } from './address-item';
import { useAddress } from '@theme/hooks';
import { LoaderSpinner } from '@theme/components';

interface AddressListModalProps {
  isAddressListModalOpen?: boolean;
  setIsAddressListModalOpen?: (value: boolean) => void;
  setIsNewAddressModalOpen?: (value: boolean) => void;
}

const AddressListModal = ({
  isAddressListModalOpen,
  setIsAddressListModalOpen,
  setIsNewAddressModalOpen
}: AddressListModalProps) => {
  const { t } = useLocalization();
  const { addressList } = useAddress();
  const [loaderOpen, setLoaderOpen] = useState(false)

  return (
    <FormModal
      portalId="account-address-new-address-modal"
      title={'Addresses'}
      open={isAddressListModalOpen}
      setOpen={setIsAddressListModalOpen}
      className="max-h-[90vh] w-full overflow-y-auto sm:w-[28rem] z-[99999]"
    >
      {loaderOpen &&
      (
        <div className='absolute bg-white w-full z-50 h-[91vh] bg-opacity-80'>
          <LoaderSpinner />
        </div>
      )
      }
      <div className="flex h-[91vh] w-full flex-col">
        <div className="flex w-full flex-grow flex-col gap-y-[14px] overflow-y-auto px-5 pb-4">
          {addressList?.map((address, index) => (
            <div key={address.pk}>
              <AddressItem address={address} index={index} setModalOpen={setIsAddressListModalOpen} setLoaderOpen={setLoaderOpen} />
            </div>
          ))}
        </div>
        <div className="bottom-0 start-0 flex h-[75px] w-full items-center justify-between bg-white px-5 py-3 shadow-addresses">
          <div className="text-lg leading-none text-gray-620">
            {t('basket.address.find_address')}:
            <span
              onClick={() => {
                setIsNewAddressModalOpen(true);
                setIsAddressListModalOpen(false);
              }}
              className="cursor-pointer ps-1 text-primary"
            >
              {t('basket.address.add_new')}
            </span>
          </div>
        </div>
      </div>
    </FormModal>
  );
};

export default AddressListModal;
