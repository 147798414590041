'use client';

import React from 'react';
import { Address } from '@akinon/next/types';
import { Icon } from '@theme/components';
import { useLocalization } from '@akinon/next/hooks';
import { twMerge } from 'tailwind-merge';
import { useAddress } from '@theme/hooks';

export interface Props {
  address: Address;
  index: number;
  setIsEditAddressModalOpen?: (value: boolean) => void;
  setAddress: (value: Address) => void;
}

export const AddressCard = (props: Props) => {
  const { t } = useLocalization();
  const { address, index, setIsEditAddressModalOpen, setAddress } = props;
  const { setDefaultAddress } = useAddress();

  return (
    <div
      key={address?.pk || index}
      className={twMerge(
        'flex h-full flex-col gap-3.5',
        'rounded-xl border border-gray-300 bg-gray-25 px-4 py-3',
        (address?.extra_field as { address_primary: string })
          ?.address_primary === 'true' && 'border-primary'
      )}
    >
      <div className="flex flex-row items-center justify-between">
        <div className="flex items-center justify-start gap-3.5">
          {(address.title == 'Home' && (
            <Icon name="home" className="text-primary" size={18} />
          )) ||
            (address.title == 'Office' && (
              <Icon name="building" className="text-primary" size={18} />
            )) ||
            (address.title == 'Friend House' && (
              <Icon name="profile-2user" className="text-primary" size={18} />
            )) ||
            (address.title == 'Other' && (
              <Icon name="building-2" className="text-primary" size={18} />
            ))}
          <h2 className="text-sm font-semibold text-black">
            {address?.title == 'Friend House'
              ? "Friend's House"
              : address?.title}
          </h2>
        </div>
        <span
          className="cursor-pointer"
          onClick={() => {
            setIsEditAddressModalOpen(true);
            setAddress(address);
          }}
        >
          <Icon name="edit" className="text-primary" size={18} />
        </span>
      </div>
      <span
        title={address?.line}
        className="w-full max-w-[17.1875rem] text-sm font-normal text-black"
      >
        {address?.line?.split(' | ').map((line, index) => (
          <span className='w-full whitespace-normal break-words' key={index}>
            {line.split(': ')[1]}
            {', '}
          </span>
        ))}
        {address?.city?.name}
      </span>

      {(address?.extra_field as { address_primary: string })
        ?.address_primary === 'true' ? (
        <span className="w-fit rounded-[7.5rem] bg-primary bg-opacity-10 px-2.5 py-1 text-sm font-normal text-primary">
          {t('account.address_book.card.primary')}
        </span>
      ) : (
        <span
          onClick={() => {
            setDefaultAddress(address);
          }}
          className="w-fit cursor-pointer rounded-[7.5rem] px-2.5 py-1 text-sm font-normal text-primary"
        >
          {t('account.address_book.card.set_default')}
        </span>
      )}
    </div>
  );
};
