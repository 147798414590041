'use client';

import React from 'react';
import { Address } from '@akinon/next/types';
import { Icon, Radio } from '@theme/components';
import { useLocalization } from '@akinon/next/hooks';
import clsx from 'clsx';
import useUserLocation from '@theme/hooks/use-user-location';
import { useAddress } from '@theme/hooks';
import { twMerge } from 'tailwind-merge';
import { setCookie } from '@akinon/next/utils';

export interface Props {
  address: Address;
  index: number;
  setModalOpen: (value: boolean) => void;
  setLoaderOpen?: (value: boolean) => void;
}

export const AddressItem = (props: Props) => {
  const { t } = useLocalization();
  const { address, index, setModalOpen, setLoaderOpen } = props;
  const { setLocation } = useUserLocation();
  const { setDefaultAddress, selectedAddress, setSelectedAddress } =
    useAddress();
  const date = new Date();
  date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);

  const handleCardClick = async (data) => {
    setLoaderOpen(true);
    const ffcInfo = {
      latitude: data?.extra_field.latitude,
      longitude: data?.extra_field.longitude
    };

    setSelectedAddress(data);
    setCookie('selectedAddress', data.pk);
    document.cookie =
      'isSelectedAddressChange' +
      '=' +
      true +
      '; expires=' +
      date.toUTCString() +
      '; path=/; SameSite=None; Secure';
    await setLocation(ffcInfo);
    setModalOpen(false);
    setLoaderOpen(false);
  };

  return (
    <div className="relative">
      <div
        onClick={() => handleCardClick(address)}
        key={address?.pk || index}
        className={twMerge(
          clsx(
            'flex flex-col gap-3.5',
            'rounded-xl border bg-gray-25 px-4 pt-3',
            Number(selectedAddress?.pk) === address.pk
              ? 'border-primary'
              : 'border-gray-175'
          )
        )}
      >
        <div className="z-10 cursor-pointer pb-10">
          <div className="flex flex-row items-center justify-between">
            <div className="flex items-center justify-start gap-3.5">
              {(address.title == 'Home' && (
                <Icon name="home" className="text-primary" size={18} />
              )) ||
                (address.title == 'Office' && (
                  <Icon name="building" className="text-primary" size={18} />
                )) ||
                (address.title == 'Friend House' && (
                  <Icon
                    name="profile-2user"
                    className="text-primary"
                    size={18}
                  />
                )) ||
                (address.title == 'Other' && (
                  <Icon name="building-2" className="text-primary" size={18} />
                ))}
              <h2 className="text-sm font-semibold text-black">
                {address?.title == 'Friend House'
                  ? "Friend's House"
                  : address?.title}
              </h2>
            </div>
            <span className="cursor-pointer">
              <Radio
                inputWidth={16}
                inputHeight={16}
                name="addressRadio"
                checked={Number(selectedAddress?.pk) === address.pk}
                onChange={() => handleCardClick(address)}
              />
            </span>
          </div>

          <span
            title={address?.line}
            className="w-full max-w-[17.1875rem] text-sm font-normal text-black"
          >
            {address?.line.replace('|', ', ')}
          </span>
        </div>
      </div>

      {(address?.extra_field as { address_primary: string })
        ?.address_primary === 'true' ? (
        <span className="absolute bottom-3 left-3 w-fit rounded-[7.5rem] bg-primary bg-opacity-10 px-2.5 py-1 text-sm font-normal text-primary">
          {t('account.address_book.card.primary')}
        </span>
      ) : (
        <span
          onClick={() => setDefaultAddress(address)}
          className="absolute bottom-3 left-3 z-30 w-fit cursor-pointer rounded-[7.5rem] px-2.5 py-1 text-sm font-normal text-primary"
        >
          {t('account.address_book.card.set_default')}
        </span>
      )}
    </div>
  );
};
