'use client';

import { useAddAddressMutation } from '@akinon/next/data/client/address';
import { useLocalization } from '@akinon/next/hooks';
import { FormModal } from '@theme/components/form-modal';
import React from 'react';
import { AddressForm } from '../account';
import { useAddress } from '@theme/hooks';
import useUserLocation from '@theme/hooks/use-user-location';
import { getCookie, setCookie } from '@akinon/next/utils';

interface NewAddressModalProps {
  isNewAddressModalOpen?: boolean;
  setIsNewAddressModalOpen?: (value: boolean) => void;
}

const NewAddressModal = ({
  isNewAddressModalOpen,
  setIsNewAddressModalOpen
}: NewAddressModalProps) => {
  const { t } = useLocalization();
  const [addAddress] = useAddAddressMutation();
  const { setLocation } = useUserLocation();
  const { unsetPrimaryAddress, setSelectedAddress, primaryAddress } =
    useAddress();

  const onSubmit = async (addressData) => {
    try {
      const hasPrimaryAddress =
        primaryAddress &&
        (primaryAddress.extra_field as { address_primary: string })
          ?.address_primary === 'true';

      const shouldBePrimary = !hasPrimaryAddress || addressData.primary;

      if (shouldBePrimary && hasPrimaryAddress) {
        await unsetPrimaryAddress();
      }

      const addressWithTimestamp = {
        ...addressData,
        address_primary: shouldBePrimary.toString(),
        ffc_last_updated_datetime: new Date().toISOString()
      };

      const newAddress = await addAddress(addressWithTimestamp).unwrap();

      const ffcInfo = {
        latitude: (newAddress.extra_field as { latitude: number }).latitude,
        longitude: (newAddress.extra_field as { longitude: number }).longitude
      };

      setSelectedAddress(newAddress);
      await setLocation(ffcInfo);
      setCookie('selectedAddress', newAddress.pk.toString());

      setIsNewAddressModalOpen(false);
    } catch (error) {
      console.error('Error adding address:', error);
    }
  };

  return (
    <FormModal
      portalId="account-address-new-address-modal"
      title={t('account.address_book.modal.add_new_address')}
      open={isNewAddressModalOpen}
      setOpen={setIsNewAddressModalOpen}
      className="z-[99999] flex w-full flex-col overflow-y-auto sm:w-[28rem]"
      childrenClassName="h-full"
    >
      <AddressForm onSubmit={onSubmit} />
    </FormModal>
  );
};

export default NewAddressModal;
